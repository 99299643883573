// eslint-disable-next-line no-restricted-imports
import {observe} from '@github/selector-observer'

const screenFocusObserver = new IntersectionObserver(
  (entries: IntersectionObserverEntry[]) => {
    for (const entry of entries) {
      entry.isIntersecting ? entry.target.removeAttribute('tabindex') : entry.target.setAttribute('tabindex', '-1')
    }
  },
  {
    rootMargin: `0% 0% 0% 0%`,
    threshold: 0,
  },
)

observe('.js-home-repo-card', element => {
  screenFocusObserver.observe(element)
})
